import './Footer.css';
import { useClients } from '../../context/ClientContext.js';

const Footer = ({isLoading}) => {
    const {client, clientNotFound} = useClients()

    return(
        <div className={isLoading || clientNotFound || !client.color_footer_claro ? 'footer oscuro' : 'footer' }>
            <a className="href-imagen" href="https://pido-online.com.ar/" target='_blank'><img src='./images/footer.svg' alt='logo pido-online' /></a>
        </div>
    );
}

export default Footer;