import './Categorias.css'
import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode } from "swiper";
import { useClients } from '../../context/ClientContext';

const Categorias = (props) => {
    const primerCategoria = props.datos[0];
    const [catActiva, setCatActiva] = useState(primerCategoria);
    const {client} = useClients()

    const seleccionada = (activa) => {
        setCatActiva(activa);
        props.selec(activa);
    }

    /*return(
        <div className='categorias'>
            {props.datos.map((categoria, index) =>
                {
                if(categoria == catActiva) {
                    return <div className="categoryButton active" key={index}>
                                {categoria}
                           </div>
                } else { 
                    return <div className="categoryButton" 
                                key={index} 
                                onClick={() => seleccionada(categoria)}>
                                    {categoria}
                            </div>
                }}
            )}
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper">
                <SwiperSlide>Slide 1</SwiperSlide>
                <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide>
            </Swiper>
        </div>
    );*/

    return(
            <Swiper
                slidesPerView={'auto'}
                // spaceBetween={30}
                freeMode={true}
                modules={[FreeMode]}
                centerInsufficientSlides={true}
                className='categorias'>
                {props.datos.map((categoria, index) =>
                    {
                    if(categoria == catActiva) {
                        return <SwiperSlide className={props.isLoading || client.color_cuerpo_claro ? "categoryButton active" : "categoryButton active oscuro"} key={index}>{categoria}</SwiperSlide>
                    } else { 
                        return <SwiperSlide className={props.isLoading || client.color_cuerpo_claro ? "categoryButton" : "categoryButton oscuro"} 
                                    key={index}
                                    onClick={() => seleccionada(categoria)}>{categoria}
                                </SwiperSlide>
                    }}
                )}
            </Swiper>
    );

}

export default Categorias;