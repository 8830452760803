import './List.css';
import Item from '../Item/Item'
import ItemLoader from '../Item/ItemLoader';
import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { useClients } from '../../context/ClientContext.js';

const List = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const {client} = useClients()

    useEffect(() => {
        const font = new FontFaceObserver("Segma")

        font.load(null, 60000).then(() => {
            setIsLoading(false)
        })
    })

    return (
        <div className='list container'>
            {!props.isLoading && client.has_banner &&
            (client.has_banner_mobile
            ? <picture>
			    <source srcSet={client.banner_url} media="(min-width: 768px)" alt='banner' />
			    <img srcSet={client.banner_mobile_url} alt="banner mobile" />
		    </picture>
            : <img src={client.banner_url} alt='banner' />
            )
            }
            <h3 className={isLoading || client.color_cuerpo_claro ? 'col-12' : 'col-12 h3-oscuro'}>{props.selec}</h3>
            <hr className={!isLoading && !client.color_cuerpo_claro ? 'col-12 h3-oscuro' : ''} />
            {isLoading
            ? <div className='row'>
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
                <ItemLoader isLoading={props.isLoading} color_cuerpo_claro={client.color_cuerpo_claro} />
            </div>
            : <div className='row'>
                {props.datos.map((producto, index) => 
                    {
                    if(producto.category_name == props.selec || props.selec == "Todos"
                        && producto.category_name != "") {
                        return <Item
                                    key={index}
                                    id={producto.id}
                                    name={producto.name}
                                    description={producto.description}
                                    price={producto.price}
                                    active={producto.active}
                                    has_image={producto.has_image}
                                    image_url={producto.image_url}
                                    has_stock={producto.has_stock}
                                    category_name={producto.category_name}
                                    owner={producto.owner}
                                />
                    }}
                )}
            </div>
            }
        </div>
    );
}

export default List;