import { createContext, useContext, useEffect, useState } from "react";
import { verifyTokenRequest, logoutRequest } from '../api/auth.js'
import Cookies from 'js-cookie'

export const AuthContext = createContext()

export const useAuth = () => {
    const context = useContext(AuthContext)
    if(!context) throw new Error("useAuth must be used within an AuthProvider")

    return context
}

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function checkLogin () {
            const cookies = Cookies.get()

            if(!cookies.tokenPidoOnline) {
                setIsAuthenticated(false)
                setUser(null)
            } else {
                try {
                    const res = await verifyTokenRequest()
                    if(!res.data) {
                        setIsAuthenticated(false)
                        setUser(null)
                        return;
                    }

                    setIsAuthenticated(true)
                    setUser(res.data)
                } catch(error) {
                    setIsAuthenticated(false)
                    setUser(null)
                }
            }
            setLoading(false)
        }

        checkLogin()
    }, [])

    const logOut = async () => {
        const res = await logoutRequest()
        setIsAuthenticated(false)
        setUser(null)
    }

    return(
        <AuthContext.Provider value={{user, isAuthenticated, loading, logOut}}>
            {children}
        </AuthContext.Provider>
    )
}