import './ItemLoader.css'

const ItemLoader = ({isLoading, color_cuerpo_claro}) => {
    return (
        <ul className='col-12 col-md-6 item-box'>
            <div className={isLoading || color_cuerpo_claro ? 'item' : 'item oscuro'}>
                <div className='itemText'>
                    <div>
                        <li className={isLoading || color_cuerpo_claro ? 'name-loader' : 'name-loader oscuro'}></li>
                        <div className={isLoading || color_cuerpo_claro ? 'description-loader' : 'description-loader oscuro'}>
                            <li className='description'>
                            </li>
                        </div>
                    </div>
                    <div className='precio-box'>
                        <li className={isLoading || color_cuerpo_claro ? 'precio-loader' : 'precio-loader oscuro'}>
                        </li>
                    </div>
                </div>
                <div className='itemImg'>
                    <div className={isLoading || color_cuerpo_claro ? 'itemImgLoader-box' : 'itemImgLoader-box oscuro' }>
                    </div>
                </div>
            </div>
        </ul>
    )
}

export default ItemLoader